import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedSpId: 0,
    chat: [],
    notif: null,
    socketState: 0,
};

export const wsSlice = createSlice({
    name: "wsSlice",
    initialState,
    reducers: {
        setChat: (state, action) => {
            state.chat.push(action.payload);
        },
        setSelectedSpId: (state, action) => {
            state.selectedSpId = action.payload;
        },
        setNotification: (state, action) => {
            state.notif = action.payload;
        },
        setSocketState: (state, action) => {
            state.socketState = action.payload;
        },
    },
});

export const {
    setChat,
    setSelectedSpId,
    setNotification,
    setSocketState,
} = wsSlice.actions;

export default wsSlice.reducer;
