import React, { useEffect, useState } from "react";
import {
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    ListItem,
    Step,
    StepContent,
    StepLabel,
    Typography,
    Stepper,
    AlertTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPersonWalkingLuggage,
    faDoorClosed,
} from "@fortawesome/free-solid-svg-icons";
import { LoadingButton } from "@mui/lab";
import MyProfile from "./profile/MyProfile";
import PropertyAdd from "./PropertyAdd";
import UpdateICalendar from "./iCalendar/UpdateICalendar";
import PartnerListForm from "./IoT/PartnerListForm";
import {
    showLoader,
    updateSignUpProcessFlow,
} from "../features/auth/authSlice";
import {
    STATUS_CODE_ADD_BASIC_DEVICES_COMPLETE,
    STATUS_CODE_ADD_CALENDAR_COMPLETE,
    STATUS_CODE_ADD_PROPERTY_COMPLETE,
    STATUS_CODE_OTP_SUCCESS,
    STATUS_CODE_PCR_COMPLETE,
    STATUS_CODE_PROFILE_COMPLETE,
    STATUS_CODE_TESTALERT_COMPLETE,
} from "../utils/SignUpStatusCode";
import TestAlertButton from "./IoT/TestAlertButton";
import { CheckBasicSensors, getDevicesbyProperty } from "../api/IoT/devices";
import PCoRSetUp from "./IoT/PCoRSetUp";
import HouseCard from "./helper/HouseCard";
import { useUpdateSignUpProcessMutation } from "../features/auth/hostAPI";
import { useLazyGetPremadeRulesAndRuleGroupsByPropertyQuery } from "../features/auth/rulesAPI";
import { useGetAllPartnersQuery } from "../features/auth/partnersAPI";
import CustomAlertDialog from "./helper/CustomAlertDialog";
import { useCreateHouseTimerMutation } from "../features/auth/devicesAPI";

const STEP_USERPROFILE = 0;
const STEP_ADDPROPERTY = 1;
const STEP_ICALENDAR = 2;
const STEP_DEVICES = 3;
const STEP_PCR = 4;
const STEP_SMS = 5;

function RequiredInfo() {
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state.auth.userInfo);
    const signUpProcessFlow = useSelector(
        (state) => state.auth.signUpProcessFlow,
    );
    const [alertSeverity, setAlertSeverity] = useState("");
    const [activeStep, setActiveStep] = useState(0);
    const [iCalendar, setICalendar] = useState(null);
    const [propertyId, setPropertyId] = useState(0);
    const [timeZone, setTimeZone] = useState(null);
    const [apidevData, setapidevData] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [showNoteOnCheckInOutDoors, setshowNoteOnCheckInOutDoors] = useState(false);
    const [alertMessage, setAlertMessage] = useState([]);
    const [alertTitle, setAlertTitle] = useState("");
    const [skipTitle, setSkipTitle] = useState("");
    const [addTitle, setAddTitle] = useState("");
    const [skipDisabled, setSkipDisabled] = useState(false);
    const [skipLoading, setSkipLoading] = useState(false);
    const [addMoreLoading, setAddMoreLoading] = useState(false);
    const [showPartnerList, setShowPartnerList] = useState(true);
    const [basicSensorsComplete, setBasicSensorsComplete] = useState(false);
    const [reloadPartnerList, setReloadPartnerList] = useState(false);
    const [deviceFailedAlert, setDeviceFailedAlert] = useState(false);
    const [premadeRulesFailed, setPremadeRulesFailed] = useState(false);
    const [relTime, setRelTime] = useState({});
    const dispatch = useDispatch();
    const [updateSignUpProcess, {
        isLoading: isLoadingUpdateSignUpProcess,
    }] = useUpdateSignUpProcessMutation();

    const [getPremadeRulesAndRuleGroups, {
        data: dataGetPremadeRulesAndRuleGroups,
        isLoading: isLoadingGetPremadeRulesAndRuleGroups,
        isSuccess: isSuccessGetPremadeRulesAndRuleGroups,
    }] = useLazyGetPremadeRulesAndRuleGroupsByPropertyQuery({ propertyId });

    const {
        data: dataGetAllPartners,
    } = useGetAllPartnersQuery();

    useEffect(() => {
        if (dataGetAllPartners !== null) {
            setapidevData(dataGetAllPartners);
        }
    }, [dataGetAllPartners]);

    const [
        createHouseTimer,
        {
            error: errorUCreateHouseTimerMutation,
            isError: isErrorUCreateHouseTimerMutation,
            isSuccess: isSuccessUCreateHouseTimerMutation,
            isLoading: isLoadingUCreateHouseTimerMutation,
        },
    ] = useCreateHouseTimerMutation();

    useEffect(() => {
        switch (signUpProcessFlow.statusCode) {
            case STATUS_CODE_OTP_SUCCESS:
                setActiveStep(STEP_USERPROFILE);
                break;
            case STATUS_CODE_PROFILE_COMPLETE:
                setActiveStep(STEP_ADDPROPERTY);
                break;
            case STATUS_CODE_ADD_PROPERTY_COMPLETE:
                setActiveStep(STEP_ICALENDAR);
                setPropertyId(signUpProcessFlow.initialPropertyId);
                setTimeZone(signUpProcessFlow.initialPropertyTimezone);
                break;
            case STATUS_CODE_ADD_CALENDAR_COMPLETE:
                setActiveStep(STEP_DEVICES);
                setPropertyId(signUpProcessFlow.initialPropertyId);
                (async () => {
                    const hasBasicSensors = await CheckBasicSensors(
                        signUpProcessFlow.initialPropertyId,
                    );
                    if (
                        hasBasicSensors.hasMainDoorSensor
            && hasBasicSensors.hasMotionSensor
                    ) {
                        setSkipTitle("Skip Step");
                        setSkipDisabled(false);
                        setBasicSensorsComplete(true);
                    }
                })();
                break;
            case STATUS_CODE_ADD_BASIC_DEVICES_COMPLETE:
                setPropertyId(signUpProcessFlow.initialPropertyId);
                (async () => {
                    dispatch(
                        showLoader({
                            open: true,
                            message: "Please wait.",
                        }),
                    );
                    await getPremadeRulesAndRuleGroups({
                        propertyId: signUpProcessFlow.initialPropertyId,
                    }).then((data) => {
                        if (data.isSuccess) {
                            data.data.rules.forEach((r) => {
                                if (
                                    r.rule.name === "Post check-out routine ongoing"
                                ) {
                                    setRelTime(r.relativeTimeCondition);
                                }
                            });
                        }
                        if (data.isError) {
                            setPremadeRulesFailed(true);
                        }
                    });
                    dispatch(showLoader({ open: false, message: "" }));
                })();
                setTimeout(() => setActiveStep(STEP_PCR), 3000);
                break;
            case STATUS_CODE_PCR_COMPLETE:
                (async () => {
                    await createHouseTimer();
                })();
                setPropertyId(signUpProcessFlow.initialPropertyId);
                setActiveStep(STEP_SMS);
                break;
            default:
                navigate("/property");
        }
    }, [
        dispatch,
        navigate,
        signUpProcessFlow.initialPropertyId,
        signUpProcessFlow.initialPropertyTimezone,
        signUpProcessFlow.statusCode,
        reloadPartnerList,
    ]);

    const callbackProfile = () => {
        setActiveStep(STEP_ADDPROPERTY);
        dispatch(
            updateSignUpProcessFlow({
                statusCode: STATUS_CODE_PROFILE_COMPLETE,
            }),
        );
    };

    const callbackProperty = (dataProperty) => {
        setPropertyId(dataProperty.id);
        setTimeZone(dataProperty.timeZone);
        dispatch(
            updateSignUpProcessFlow({
                statusCode: STATUS_CODE_ADD_PROPERTY_COMPLETE,
                initialPropertyId: dataProperty.id,
                initialPropertyTimezone: dataProperty.timeZone,
            }),
        );
        setActiveStep(STEP_ICALENDAR);
    };

    const updateSignUpFlow = async (statusCode) => {
        await updateSignUpProcess({ statusCode });
    };

    const callbackScheduler = async () => {
        await updateSignUpFlow(STATUS_CODE_ADD_CALENDAR_COMPLETE);
        setActiveStep(STEP_DEVICES);
    };

    const onSkipPostCheckOutRoutine = async () => {
        setSkipLoading(true);
        await updateSignUpFlow(STATUS_CODE_TESTALERT_COMPLETE);
    };

    const OnSkipHandler = async () => {
        setSkipLoading(true);
        setDeviceFailedAlert(false);
        setBasicSensorsComplete(false);
        setShowAlert(false);
        setshowNoteOnCheckInOutDoors(true);
        setShowPartnerList(false);
        await updateSignUpFlow(STATUS_CODE_ADD_BASIC_DEVICES_COMPLETE);
        setSkipLoading(false);
    };

    const onAddMoreHandler = async () => {
        setAddMoreLoading(true);
        await API.get("UserAPI", "/getSignUpProcessStatus").then(async (res) => {
            await updateSignUpFlow(res);
        });
        setShowAlert(false);
        setAddMoreLoading(false);
    };

    // this sets the next signup process
    const callbackDevice = async (
        title,
        message,
        severity,
        cantSkip,
        skipLabel,
        addLabel,
    ) => {
        setDeviceFailedAlert(false);
        if (!cantSkip) {
            setBasicSensorsComplete(true);
        } else {
            setBasicSensorsComplete(false);
        }
        setAlertSeverity(severity);
        setAlertTitle(title);
        setSkipTitle(skipLabel);
        setAddTitle(addLabel);
        setAlertMessage(message);
        setSkipDisabled(cantSkip);
        setShowAlert(true);
    };

    const callbackPCoR = async (setLoading) => {
        await updateSignUpFlow(STATUS_CODE_PCR_COMPLETE);
        setLoading(false);
    };

    return (
        <HouseCard smallImage px={8} py={3}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Stepper activeStep={activeStep}>
                        <Step sx={{ padding: 0 }}>
                            <StepLabel sx={{ marginRight: -1 }} />
                        </Step>
                        <Step sx={{ padding: 0 }}>
                            <StepLabel sx={{ marginRight: -1 }} />
                        </Step>
                        <Step sx={{ padding: 0 }}>
                            <StepLabel sx={{ marginRight: -1 }} />
                        </Step>
                        <Step sx={{ padding: 0 }}>
                            <StepLabel sx={{ marginRight: -1 }} />
                        </Step>
                        <Step sx={{ padding: 0 }}>
                            <StepLabel sx={{ marginRight: -1 }} />
                        </Step>
                        <Step sx={{ padding: 0 }}>
                            <StepLabel sx={{ marginRight: -1 }} />
                        </Step>
                    </Stepper>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        {activeStep === 0
                            ? "Profile"
                            : activeStep === 1
                                ? "Add Property"
                                : activeStep === 2
                                    ? "Add Booking Schedule to the Property"
                                    : activeStep === 3
                                        ? "Add Device to the Property"
                                        : activeStep === 4
                                            ? "Set up the Post Check-Out Routine"
                                            : activeStep === 5
                                                ? "Test Alerts"
                                                : ""}
                    </Typography>
                </Grid>
                {activeStep === 0 && (
                    <Grid item xs={12}>
                        <MyProfile callbackRequire={callbackProfile} />
                    </Grid>
                )}
                {activeStep === 1 && (
                    <Grid item xs={12}>
                        <PropertyAdd callbackRequire={callbackProperty} />
                    </Grid>
                )}
                {activeStep === 2 && propertyId !== 0 && timeZone !== null && (
                    <Grid item xs={12}>
                        <UpdateICalendar
                            callbackRequired={callbackScheduler}
                            propertyFK={propertyId}
                            iCalendar={iCalendar}
                            setICalendar={setICalendar}
                            timeZone={timeZone}
                            standardCheckInTime={userInfo.standardCheckInTime}
                            standardCheckOutTime={userInfo.standardCheckOutTime}
                        />
                    </Grid>
                )}
                {activeStep === 3 && (
                    <Grid item xs={12}>
                        {showAlert && (
                            <CustomAlertDialog
                                open={showAlert}
                                title={alertTitle}
                                message={alertMessage}
                                dialogType={alertSeverity}
                                okButtonText={addTitle}
                                cancelButtonText={skipTitle}
                                callbackOk={onAddMoreHandler}
                                callbackCancel={OnSkipHandler}
                                cancelLoading={skipLoading}
                                okLoading={addMoreLoading}
                            />
                        )}
                        {showNoteOnCheckInOutDoors && (
                            <Alert
                                severity="info"
                                variant="standard"
                                onClose={() => {
                                    setShowAlert(false);
                                }}
                            >
                                Devices are being added...
                                {" "}
                                <br />
                                <br />
                                <strong>Note:</strong>
                                {" "}
                                Check-In/Out Door Sensors
                                {" "}
                                <span className="fa-layers">
                                    <FontAwesomeIcon
                                        size="lg"
                                        icon={faDoorClosed}
                                        color="#cceacc"
                                        style={{ marginLeft: 5 }}
                                    />
                                    <FontAwesomeIcon
                                        icon={faPersonWalkingLuggage}
                                        color="green"
                                        style={{
                                            margin: "0px 0px 0px 0px",
                                        }}
                                    />
                                </span>
                                {" "}
                                <span style={{ marginLeft: "10px" }}>
                                    are used to notify hosts that their guests have
                                    arrived/left. Door sensors can be toggled in/out of this
                                    alert group by clicking on their
                                </span>
                                {" "}
                                <span className="fa-layers">
                                    <FontAwesomeIcon
                                        size="lg"
                                        icon={faDoorClosed}
                                        color="#cceacc"
                                        style={{ marginLeft: 5 }}
                                    />
                                    <FontAwesomeIcon
                                        icon={faPersonWalkingLuggage}
                                        color="green"
                                        style={{
                                            margin: "0px 0px 0px 0px",
                                        }}
                                    />
                                </span>
                                {" "}
                                <span style={{ marginLeft: "10px" }}>icon in Devices.</span>
                            </Alert>
                        )}
                        {apidevData && showPartnerList && (
                            <PartnerListForm
                                partnerList={apidevData}
                                propertyId={propertyId}
                                callbackRequired={callbackDevice}
                                forSignUp
                            />
                        )}
                        {basicSensorsComplete && showPartnerList && (
                            <LoadingButton
                                sx={{ marginTop: "2rem" }}
                                variant="contained"
                                loading={skipLoading}
                                loadingPosition="center"
                                onClick={OnSkipHandler}
                                disabled={skipDisabled}
                            >
                                Skip step
                            </LoadingButton>
                        )}
                    </Grid>
                )}
                {activeStep === 4 && (
                    <Grid item xs={12}>
                        {!premadeRulesFailed ? (
                            <>
                                <Alert
                                    severity="info"
                                    variant="standard"
                                    sx={{ marginBottom: "2rem" }}
                                >
                                    {/* <Typography> */}
                                    <strong>Note:</strong>
                                    {" "}
                                    The Post Check-out Routine
                                    automatically checks the property using sensors
                                    {" "}
                                    <strong>30 minutes after check-out.</strong>
                                    {" "}
                                    You can change
                                    this default time now or later in the
                                    <strong> Alerts</strong>
                                    {" "}
                                    tab.
                                    {/* </Typography> */}
                                </Alert>

                                <PCoRSetUp
                                    callbackRequired={callbackPCoR}
                                    relTimeCond={relTime}
                                />
                            </>
                        ) : (
                            <>
                                <Alert severity="error" variant="standard">
                                    {/* <Typography> */}
                                    <strong>Note:</strong>
                                    {" "}
                                    The Post Check-out Routine
                                    automatically checks the property using sensors
                                    {" "}
                                    <strong>30 minutes after check-out.</strong>
                                    {" "}
                                    <div style={{ marginTop: "1rem" }}>
                                        <span>
                                            Unfortunately, the previous step is taking longer than
                                            expected. We will be skipping the current and next step,
                                            but you can always change this default time later in the
                                            <strong> Alerts</strong>
                                            {" "}
                                            tab.
                                        </span>
                                    </div>
                                    {/* </Typography> */}
                                </Alert>
                                <LoadingButton
                                    sx={{ marginTop: "2rem" }}
                                    variant="contained"
                                    loading={skipLoading}
                                    loadingPosition="center"
                                    onClick={onSkipPostCheckOutRoutine}
                                    disabled={skipDisabled}
                                >
                                    Skip step
                                </LoadingButton>
                            </>
                        )}
                    </Grid>
                )}
                {activeStep === 5 && (
                    <Grid item xs={12}>
                        <TestAlertButton propertyId={propertyId} />
                    </Grid>
                )}
            </Grid>
        </HouseCard>
    );
}

export default RequiredInfo;
