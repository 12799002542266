import { useEffect, useState } from "react";
import { Alert, Box, Button, CardContent, Grid, Typography, useTheme } from "@mui/material";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MuiTelInput } from "mui-tel-input";
import { MuiOtpInput } from "mui-one-time-password-input";
import HouseCard from "./helper/HouseCard";
import { showLoader } from "../features/auth/authSlice";
import CustomPasswordField from "./shared/custom_password";
import CustomPhoneInput from "./shared/custom_phoneinput";
import { CustomPasswordConfirmationRequirements, CustomPasswordRequirements, isPasswordValid } from "./shared/custom_password_validator";

export default function ForgotPassword() {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [phone, setPhone] = useState("");
    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [formError, setFormError] = useState({
        show: false,
        message: "",
    });
    const [formIsComplete, setFormIsValid] = useState(false);

    useEffect(() => {
        // form validation
        if (code.length !== 0
            && isPasswordValid(password)
            && (password === confirmPassword)) setFormIsValid(true);
        else setFormIsValid(false);
    }, [code, password, confirmPassword]);

    const handleResetPassword = async (username) => {
        setFormError((prev) => ({
            ...prev,
            show: false,
            message: "",
        }));
        try {
            dispatch(showLoader({ open: true, message: "Sending code..." }));
            const user = username.replace("+", "").replace(/\s+/g, "").trim();
            const output = await Auth.forgotPassword(user);
            setShowResetPassword(true);
        } catch (err) {
            setFormError((prev) => ({
                ...prev,
                show: true,
                message: err.message,
            }));
        } finally {
            dispatch(showLoader({ open: false, message: "" }));
        }
    };

    const handleConfirmedResetPassword = async ({
        username, confirmationCode, newPassword,
    }) => {
        try {
            dispatch(showLoader({ open: true, message: "Please wait..." }));
            const user = username.replace("+", "").replace(/\s+/g, "").trim();
            const data = await Auth.forgotPasswordSubmit(user, confirmationCode, newPassword);
            const loginResult = await Auth.signIn(user, newPassword);
            window.location.replace("/property");
        } catch (error) {
            setFormError((prev) => ({
                ...prev,
                show: true,
                message: error.message,
            }));
        } finally {
            dispatch(showLoader({ open: false, message: "" }));
        }
    };

    const RequestResetPasswordComponent = (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {/* <MuiTelInput
                    label="Phone"
                    size="small"
                    fullWidth
                    defaultCountry="US"
                    value={phone}
                    disableFormatting
                    onChange={(e) => {
                        setPhone(e);
                    }}
                    onKeyDown={(e) => {
                        if (e.key !== "Enter") return;
                        handleResetPassword(phone);
                    }}
                /> */}
                <CustomPhoneInput
                    label="Phone"
                    fullWidth
                    onChange={(e) => {
                        setPhone(e);
                    }}
                    onKeyDown={(e) => {
                        if (e.key !== "Enter") return;
                        handleResetPassword(phone);
                    }}
                />
                {formError.show && (
                    <Typography variant="subtitle2" color="error">
                        *
                        {" "}
                        {formError.message}
                    </Typography>
                )}
            </Grid>
            <Grid item xs={12}>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={() => handleResetPassword(phone)}
                >
                    Send code
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Button
                    fullWidth
                    variant="text"
                    // onClick={() => callbackHideThis()}
                    onClick={() => navigate("/login")}
                >
                    Back to Log In
                </Button>
            </Grid>
        </Grid>
    );

    const ResetPasswordComponent = (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <MuiOtpInput
                    inputMode="numeric"
                    length={6}
                    TextFieldsProps={{
                        size: "small",
                    }}
                    value={code}
                    onChange={(newValue) => setCode(newValue)}
                />
            </Grid>
            <Grid item xs={12}>
                <CustomPasswordField
                    label="Password"
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value);
                    }}
                />
                <CustomPasswordRequirements
                    password={password}
                />
            </Grid>
            <Grid item xs={12}>
                <CustomPasswordField
                    label="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => {
                        setConfirmPassword(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key !== "Enter") return;
                        handleConfirmedResetPassword({
                            username: phone,
                            confirmationCode: code,
                            newPassword: password,
                        });
                    }}
                />
                <CustomPasswordConfirmationRequirements
                    password={password}
                    confirmPassword={confirmPassword}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={() => handleConfirmedResetPassword({
                        username: phone,
                        confirmationCode: code,
                        newPassword: password,
                    })}
                    disabled={!formIsComplete}
                >
                    Submit
                </Button>
            </Grid>
        </Grid>
    );

    return (
        <HouseCard px={8} py={4}>
            <Box height="100%" display="flex" flexDirection="column" justifyContent="center">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Forgot Password</Typography>
                    </Grid>
                    {formError.show && (
                        <Grid item xs={12}>
                            <Alert
                                severity="error"
                                onClose={() => setFormError((prev) => ({
                                    ...prev,
                                    show: false,
                                    message: "",
                                }))}
                            >
                                {formError.message}
                            </Alert>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        {!showResetPassword ? RequestResetPasswordComponent : ResetPasswordComponent}
                    </Grid>
                </Grid>
            </Box>
        </HouseCard>
    );
}
