import { ArrowDownward, Send } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, Paper, Stack, TextField, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useGetChatHistoryQuery, useGetChatMessageQuery, useLazyGetChatHistoryQuery, useSendChatMessageMutation } from "../../features/auth/wsAPI";
import { setSelectedSpId } from "../../features/auth/websocketSlice";
import { hostAPI } from "../../features/auth/hostAPI";

function ScrollToBottom() {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <ListItem ref={elementRef} />;
}

export default function ChatForm({
    spId,
    spName,
    show,
    callbackCloseForm,
}) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.auth.userInfo);
    const localUserTZ = useSelector((state) => state.auth.userInfo.localTimeZoneDatabaseName);
    const dateToday = new Date();
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 30;
    const scrollRef = useRef();
    const [showJumpToBottom, setShowJumpToBottom] = useState(false);
    const [lastViewedListItem, setLastViewedItem] = useState("");

    const [msgObj, setMsgObj] = useState({
        hostId: 0,
        hostName: "",
        spId: 0,
        spName: "",
        message: "",
        senderUserType: "host",
    });

    const [messageHistory, setMessageHistory] = useState([]);

    const enableSendButton = msgObj.message.length > 0;

    const [triggerGetChatHistory, {
        data: dataChatHistory,
        isLoading: isLoadingChatHistory,
        isFetching: isFetchingChatHistory,
    }] = useLazyGetChatHistoryQuery();

    const isGettingChatHistory = isLoadingChatHistory || isFetchingChatHistory;

    const {
        data: dataWsReceiveMessage,
    } = useGetChatMessageQuery();

    const [sendChat] = useSendChatMessageMutation();

    const [isSendingMessage, setIsSendingMessage] = useState(false);

    useEffect(() => {
        triggerGetChatHistory({
            recepientId: spId,
            page: currentPage,
            pageSize,
        });

        return () => {
            // reset websocket chat receiver
            dispatch(hostAPI.util.invalidateTags([{ type: "getChatMessage", id: "list" }]));
        };
    }, []);

    useEffect(() => {
        if (dataChatHistory) {
            const newChatHistory = [...dataChatHistory.results];
            const arr = [...messageHistory];

            if (currentPage !== 1) setLastViewedItem(`msgRef-${arr[0].id}`);
            else setLastViewedItem("");

            newChatHistory.sort((a, b) => b.id - a.id).forEach((item) => {
                const check = arr.find((x) => x.id === item.id);
                if (check === undefined) arr.splice(0, 0, item);
            });
            setMessageHistory(arr);
        }
    }, [dataChatHistory]);

    // force to scroll to last viewed item after loading next message history page
    useEffect(() => {
        if (lastViewedListItem !== "") {
            document.getElementById(lastViewedListItem).scrollIntoView();
        }
    }, [lastViewedListItem]);

    useEffect(() => {
        if (messageHistory.length > 0 && currentPage === 1) scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }, [messageHistory]);

    useEffect(() => {
        if (dataWsReceiveMessage.length > 0) {
            const msgToAdd = dataWsReceiveMessage[dataWsReceiveMessage.length - 1];

            if (spId === msgToAdd.spId) {
                setMessageHistory((prev) => [
                    ...prev,
                    msgToAdd,
                ]);
            }
            // reset text field
            if (msgToAdd.messageType === "sender") {
                setIsSendingMessage(false);
                setMsgObj((prev) => ({ ...prev, message: "" }));
            }
        }
    }, [dataWsReceiveMessage]);

    useEffect(() => {
        if (userInfo !== undefined || userInfo !== null) {
            setMsgObj((prev) => ({
                ...prev,
                hostId: userInfo.id,
                hostName: `${userInfo.firstName} ${userInfo.lastName}`,
            }));
        }
    }, [userInfo]);

    useEffect(() => {
        if (spId) {
            setMsgObj((prev) => ({
                ...prev,
                spId,
            }));

            dispatch(setSelectedSpId(spId));
        }
    }, [spId]);

    return (
        <Dialog
            open={show}
        >
            <DialogTitle>
                {`Send message to ${spName}`}
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <List
                            dense
                            sx={{
                                maxHeight: "600px",
                                overflow: "auto",
                            }}
                            onScroll={((e) => {
                                const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
                                const bottomList = Math.abs(scrollHeight - (scrollTop + clientHeight));

                                setShowJumpToBottom(bottomList >= 200);

                                if (scrollTop === 0) {
                                    const page = currentPage + 1;
                                    setCurrentPage(page);

                                    triggerGetChatHistory({
                                        recepientId: spId,
                                        page,
                                        pageSize,
                                    });
                                }
                            })}
                        >
                            {isGettingChatHistory && (
                                <ListItem>
                                    Loading
                                </ListItem>
                            )}
                            {messageHistory.map((i, idx) => {
                                const index = idx;
                                const convertedDate = moment.tz(i.dateSent, localUserTZ);
                                let dtSent = convertedDate.format("hh:MM A");

                                if (convertedDate.format("MM/DD/YYYY") !== dateToday.toLocaleDateString()) dtSent = `${convertedDate.format("MM/DD/YYYY")} ${dtSent}`;

                                return (
                                    <ListItem
                                        key={index}
                                        id={`msgRef-${i.id}`}
                                    >
                                        <Stack
                                            alignItems={i.messageType === "sender" ? "flex-end" : "flex-start"}
                                            sx={{
                                                width: "100%",
                                            }}
                                        >
                                            <Typography
                                                variant="caption"
                                            >
                                                {dtSent}
                                            </Typography>
                                            <Paper
                                                sx={{
                                                    padding: theme.spacing(1),
                                                    backgroundColor: i.messageType === "sender" ? theme.palette.primary.light : theme.palette.grey[300],
                                                }}
                                            >
                                                {i.message}
                                            </Paper>
                                        </Stack>
                                    </ListItem>
                                );
                            })}
                            <ListItem ref={scrollRef} />
                        </List>
                        <Box>
                            <Box
                                textAlign="center"
                                position="absolute"
                                bottom={156}
                                width="100%"
                                display={showJumpToBottom ? "block" : "none"}
                            >
                                <Button
                                    variant="contained"
                                    size="small"
                                    startIcon={<ArrowDownward />}
                                    onClick={() => scrollRef.current.scrollIntoView({
                                        behavior: "smooth",
                                    })}
                                >
                                    Jump to bottom
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: "flex",
                            direction: "row",
                        }}
                    >
                        <TextField
                            size="small"
                            onChange={(e) => setMsgObj((prev) => ({ ...prev, message: e.target.value }))}
                            value={msgObj.message}
                            fullWidth
                            disabled={isSendingMessage}
                        />
                        <IconButton
                            color="primary"
                            onClick={() => {
                                setIsSendingMessage(true);
                                sendChat({
                                    msg: msgObj,
                                });
                            }}
                            disabled={!enableSendButton || isSendingMessage}
                        >
                            <Send />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="caption"
                            padding={0}
                            textAlign="right"
                            component="div"
                            paddingRight={theme.spacing(7)}
                            color={isSendingMessage ? theme.palette.common.black : "transparent"}
                        >
                            Sending...
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {/* {connectionStatus} */}
                <Button
                    onClick={() => {
                        if (callbackCloseForm !== undefined) callbackCloseForm(false);
                        dispatch(setSelectedSpId(0));
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
