import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showLoader, updateSignUpProcessFlow } from "../../features/auth/authSlice";
import CustomPasswordField from "../shared/custom_password";
import {
    CheckRegistration,
    STATUS_CODE_ADD_CALENDAR_COMPLETE,
    STATUS_CODE_ADD_PROPERTY_COMPLETE,
    STATUS_CODE_ADD_BASIC_DEVICES_COMPLETE,
    STATUS_CODE_OTP_SUCCESS,
    STATUS_CODE_PROFILE_COMPLETE,
    STATUS_CODE_PCR_COMPLETE,
} from "../../utils/SignUpStatusCode";
import { CustomPasswordConfirmationRequirements, CustomPasswordRequirements, isPasswordValid } from "../shared/custom_password_validator";

function ChangePassword({
    userSessionForceChangePassword,
    callbackGoBackToLoginPage,
}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorPassMsg, setErrorPassMsg] = useState("");
    const dispatch = useDispatch();
    const [username, setUsername] = useState("");

    const [showSuccessMsg, setShowSuccessMsg] = useState(false);

    const [formIsComplete, setFormIsComplete] = useState(false);
    const disregardDesktopView = userSessionForceChangePassword !== undefined;

    useEffect(() => {
        if (userSessionForceChangePassword === null) {
            if (oldPassword.length !== 0
                && isPasswordValid(newPassword)
                && (newPassword === confirmPassword)) setFormIsComplete(true);
            else setFormIsComplete(false);
        } else if (userSessionForceChangePassword !== null) {
            if (isPasswordValid(newPassword)
                && (newPassword === confirmPassword)) setFormIsComplete(true);
            else setFormIsComplete(false);
        }
    }, [oldPassword, newPassword, confirmPassword]);

    const updatePassword = async (oldPass, newPass) => {
        if (!formIsComplete) return;
        dispatch(showLoader({ open: true, message: "Please wait..." }));
        setShowSuccessMsg(false);
        console.log("updatepassword", userSessionForceChangePassword);
        try {
            if (userSessionForceChangePassword === null
                || userSessionForceChangePassword === undefined
            ) {
                const user = await Auth.currentAuthenticatedUser();
                console.log(">>", user, oldPass, newPass);
                await Auth.changePassword(user, oldPass, newPass);
                setShowSuccessMsg(true);
            } else {
                const authSAession = await Auth.completeNewPassword(userSessionForceChangePassword, newPass);
                const statusObj = await CheckRegistration();
                dispatch(updateSignUpProcessFlow({ statusCode: statusObj.statusCode }));
                if (statusObj.statusCode !== null) {
                    switch (statusObj.statusCode) {
                        case STATUS_CODE_OTP_SUCCESS:
                        case STATUS_CODE_PROFILE_COMPLETE:
                        case STATUS_CODE_ADD_PROPERTY_COMPLETE:
                        case STATUS_CODE_ADD_CALENDAR_COMPLETE:
                        case STATUS_CODE_ADD_BASIC_DEVICES_COMPLETE:
                        case STATUS_CODE_PCR_COMPLETE:
                            window.location.replace("/complete-registration");
                            break;
                        default:
                            window.location.replace("/property");
                            break;
                    }
                }
            }
        } catch (error) {
            if (error.code === "LimitExceededException") setErrorPassMsg(error.message);
            else if (error.message.includes("session is expired")) setErrorPassMsg("Session is expired. Please try to log in again.");
            else setErrorPassMsg("Incorrect Old Password!");
        } finally {
            dispatch(showLoader({ open: false, message: "" }));
        }
    };

    useEffect(() => {
        if (userSessionForceChangePassword === undefined) {
            Auth.currentAuthenticatedUser({
                bypassCache: true,
            }).then((u) => {
                console.log(">>", u);
                setUsername(u.username);
            });
        } else {
            setUsername(userSessionForceChangePassword.username);
        }
    }, []);

    const blankGridItem = (
        <Grid
            item
            xs={0}
            md={6}
            sx={{
                display: (isMobile || disregardDesktopView) ? "none" : "block",
            }}
        />
    );

    return (

        <Grid container justifyContent="center" spacing={2}>
            <Grid
                item
                xs={12}
                sx={{
                    display: disregardDesktopView ? "block" : "none",
                }}
            >
                <Typography variant="h6">Force Change Password</Typography>
            </Grid>
            {showSuccessMsg && (
                <Grid item xs={12}>
                    <Alert
                        severity="success"
                        onClose={() => setShowSuccessMsg(false)}
                    >
                        Your password has been successfully changed.
                    </Alert>
                </Grid>
            )}
            {errorPassMsg.length > 0 && (
                <Grid item xs={12}>
                    <Alert
                        severity="error"
                        onClose={() => setErrorPassMsg("")}
                    >
                        {errorPassMsg}
                    </Alert>
                </Grid>
            )}
            {/* <Grid
                item
                xs={12}
                md={disregardDesktopView ? false : 6}
            >
                <TextField
                    label="Username"
                    size="small"
                    fullWidth
                    disabled
                    value={username !== "" ? username : " "}
                />
            </Grid>
            {blankGridItem} */}
            {/* {disregardDesktopView && ( */}
            <Grid
                item
                xs={12}
                md={disregardDesktopView ? false : 6}
            >
                <CustomPasswordField
                    label="Current Password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                />
            </Grid>
            {blankGridItem}
            {/* )} */}
            <Grid
                item
                xs={12}
                md={disregardDesktopView ? false : 6}
            >
                <CustomPasswordField
                    label="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <CustomPasswordRequirements
                    password={newPassword}
                />
            </Grid>
            {blankGridItem}
            <Grid
                item
                xs={12}
                md={disregardDesktopView ? false : 6}
            >
                <CustomPasswordField
                    label="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key !== "Enter") return;
                        updatePassword(oldPassword, newPassword);
                    }}
                />
                <CustomPasswordConfirmationRequirements
                    password={newPassword}
                    confirmPassword={confirmPassword}
                />
            </Grid>
            {blankGridItem}
            <Grid
                item
                xs={12}
                textAlign="right"
            >
                <Button
                    variant="contained"
                    onClick={() => updatePassword(oldPassword, newPassword)}
                    fullWidth={isMobile || disregardDesktopView}
                    disabled={!formIsComplete}
                >
                    Update Password
                </Button>
            </Grid>
            {(errorPassMsg.toLowerCase().includes("session is expired") && (
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                    <Button
                        variant="text"
                        onClick={() => callbackGoBackToLoginPage()}
                    >
                        Go back to Login Page.
                    </Button>
                </Grid>
            ))}
        </Grid>
    );
}

export default ChangePassword;
