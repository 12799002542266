import { Auth } from "aws-amplify";

export default async function AmazonConfig() {
    // hostly stacks' base urls
    const IoTUrl = process.env.REACT_APP_IOTSTACK_PARAMETER_APIURL;
    const iCalUrl = process.env.REACT_APP_ICALENDARSTACK_PARAMETER_APIURL;
    const CognitoUrl = process.env.REACT_APP_COGNITOSTACK_PARAMETER_HOSTAPIURL;
    const MessagingUrl = process.env.REACT_APP_MESSAGINGSTACK_PARAMETER_APIURL;

    const amazonConfig = {
        Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        // identityPoolId: "us-east-1:c51df1c9-c2d8-49f7-a4c1-ee18f6ef567a",
            identityPoolId: process.env.REACT_APP_IDENTITYPOOL_ID,

            // REQUIRED - Amazon Cognito Region
            region: process.env.REACT_APP_REGION,

            // OPTIONAL - Amazon Cognito User Pool ID
            // userPoolId: "us-east-1_UsLXC1QJs",
            userPoolId: process.env.REACT_APP_USERPOOL_ID,

            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            // userPoolWebClientId: "2aarjm3lfq5n9mo3vhng9ujtbd",
            userPoolWebClientId: process.env.REACT_APP_WEBCLIENT_ID,

            // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
            mandatorySignIn: false,
        },
        API: {
            endpoints: [
                {
                    name: "serverlessICalendarAPI",
                    endpoint: `${iCalUrl}api/icalendar`,
                    // custom header not yet needed but will be needed in the future
                    custom_header: async () => ({
                        Authorization: `Bearer ${(await Auth.currentSession())
                            .getIdToken()
                            .getJwtToken()}`,
                    }),
                },
                {
                    name: "serverlessBookingAPI",
                    endpoint: `${iCalUrl}api/booking`,
                    // custom header not yet needed but will be needed in the future
                    custom_header: async () => ({
                        Authorization: `Bearer ${(await Auth.currentSession())
                            .getIdToken()
                            .getJwtToken()}`,
                    }),
                },
                {
                    name: "IoTAPI",
                    endpoint: `${IoTUrl}api`,
                    // endpoint: "https://localhost:51347/api",
                    custom_header: async () => ({
                        Authorization: `Bearer ${(await Auth.currentSession())
                            .getIdToken()
                            .getJwtToken()}`,
                    }),
                },
                {
                    name: "LocalIoTAPI",
                    endpoint: "https://localhost:51347/api",
                    custom_header: async () => ({
                        Authorization: `Bearer ${(await Auth.currentSession())
                            .getIdToken()
                            .getJwtToken()}`,
                    }),
                },
                {
                    name: "MultipleDevicesListAPI",
                    endpoint: `${IoTUrl}api/devices`,
                    custom_header: async () => ({
                        Authorization: `Bearer ${(await Auth.currentSession())
                            .getIdToken()
                            .getJwtToken()}`,
                    }),
                },
                {
                    name: "CreatePremadeRuleGroupsAndRulesAPI",
                    endpoint: `${IoTUrl}create-premade-rules-rulegroups`,
                    custom_header: async () => ({
                        Authorization: `Bearer ${(await Auth.currentSession())
                            .getIdToken()
                            .getJwtToken()}`,
                    }),
                },
                {
                    name: "UserAPI",
                    endpoint: `${CognitoUrl}api/user`,
                    custom_header: async () => ({
                        Authorization: `Bearer ${(await Auth.currentSession())
                            .getIdToken()
                            .getJwtToken()}`,
                    }),
                },
                {
                    name: "UserPropertyAPI",
                    endpoint: `${CognitoUrl}api/userproperty`,
                    custom_header: async () => ({
                        Authorization: `Bearer ${(await Auth.currentSession())
                            .getIdToken()
                            .getJwtToken()}`,
                    }),
                },
                // {
                //     name: "AddaRoomAPI",
                //     endpoint: "https://localhost:63558/api/UserProperty/addRoom",
                //     custom_header: async () => ({
                //         Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                //     }),
                // },
                {
                    name: "ServiceProviderApi",
                    endpoint: `${CognitoUrl}api/serviceprovider`,
                    // endpoint: "https://localhost:63558/api/serviceprovider",
                    custom_header: async () => ({
                        Authorization: `Bearer ${(await Auth.currentSession())
                            .getIdToken()
                            .getJwtToken()}`,
                    }),
                },
                {
                    name: "NotificationApi",
                    endpoint: `${CognitoUrl}api/notification`,
                    custom_header: async () => ({
                        Authorization: `Bearer ${(await Auth.currentSession())
                            .getIdToken()
                            .getJwtToken()}`,
                    }),
                },
                {
                    name: "PublishToSNSApi",
                    endpoint: `${MessagingUrl}publishtosns`,
                    custom_header: async () => ({
                        Authorization: `Bearer ${(await Auth.currentSession())
                            .getIdToken()
                            .getJwtToken()}`,
                    }),
                },
                {
                    name: "WebSocketAPI",
                    endpoint: `${CognitoUrl}/api/ws`,
                    custom_header: async () => ({
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                    }),
                },
            ],
        },
        geo: {
            AmazonLocationService: {
                maps: {
                    items: {
                        "CognitoStack-Host-Map": {
                            // REQUIRED - Amazon Location Service Map resource name
                            style: "VectorEsriStreets", // REQUIRED - String representing the style of map resource
                        },
                    },
                    default: "CognitoStack-Host-Map", // REQUIRED - Amazon Location Service Map resource name to set as default
                },
                search_indices: {
                    items: ["CognitoStack-Host-PlaceIndex"], // REQUIRED - Amazon Location Service Place Index name
                    default: "CognitoStack-Host-PlaceIndex", // REQUIRED - Amazon Location Service Place Index name to set as default
                },
                region: "us-east-1", // REQUIRED - Amazon Location Service Region
            },
        },
    };
    return amazonConfig;
}
