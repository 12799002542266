import { AccountCircle, Dashboard, DeleteForeverSharp, ExpandLess, ExpandMore, Logout, MapsHomeWork, Menu as MenuIcon, SmartToy } from "@mui/icons-material";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import { AppBar, Box, Button, Collapse, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Menu, MenuItem, Stack, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { API, Auth } from "aws-amplify";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Link as RouterLink } from "react-router-dom";
import momentTz from "moment-timezone";
import { clear, showLoader } from "../features/auth/authSlice";
import { useGetAllPropertiesQuery } from "../features/auth/hostAPI";
import NotificationsMenuIconButton from "./NotificationsMenuIconButton";
import { useGetChatMessageQuery } from "../features/auth/wsAPI";

const StyledList = styled(List)(({ theme }) => ({
    "& .main-btn": {
        paddingLeft: theme.spacing(4),
        height: 57,
        color: "#969696",
        "&.active": {
            color: theme.palette.primary.main,
            borderLeftWidth: theme.spacing(1),
            borderLeftStyle: "solid",
            paddingLeft: theme.spacing(3),
        },
    },

    "& .main-icon": {
        color: "inherit",
    },

    "& .sub-btn": {
        paddingLeft: theme.spacing(11),
        color: "#969696",
        "&.active": {
            color: theme.palette.primary.main,
        },
    },
}));

export default function NavMenu({ drawerWidth }) {
    const theme = useTheme();
    const mobileBreakpoint = useSelector((state) => state.auth.mobileBreakpoint);
    const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const userInfo = useSelector((state) => state.auth.userInfo);
    const signUpProcessFlow = useSelector((state) => state.auth.signUpProcessFlow);
    const pageTitle = useSelector((state) => state.auth.pageTitle);
    const localUserTZ = useSelector((state) => state.auth.userInfo.localTimeZoneDatabaseName);
    const dispatch = useDispatch();

    const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
    const openProfile = Boolean(profileAnchorEl);
    const [toggleSideNav, setToggleSideNav] = React.useState(false);

    const [showProperties, setShowProperties] = React.useState(false);

    const [currentDateTime, setCurrentDateTime] = React.useState("");

    const topNavHeight = 80;

    const {
        data: dataGetAllProperties,
        isLoading: isLoadingGetAllProperties,
        isFetching: isFetchingGetAllProperties,
    } = useGetAllPropertiesQuery();

    const isGettingProperties = isLoadingGetAllProperties || isFetchingGetAllProperties;

    const {
        data: dataGetChatMessages,
    } = useGetChatMessageQuery();

    React.useEffect(() => {
        const timer = setInterval(() => {
            const convertedDate = momentTz.tz(localUserTZ);
            setCurrentDateTime(convertedDate.format("dddd MMM. DD, YYYY h:mm:ss A z"));
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [localUserTZ]);

    const deleteAllUserData = () => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("All user data tied to this account will be wiped from our system. Continue?")) {
            dispatch(showLoader({ open: true, message: "Deleting user data... This will take a while." }));
            API.del("UserAPI", "/deleteAllUserData").then(() => {
                alert("All user data deleted. Account fully wiped.");
                window.location.replace("/");
            }).catch(() => {
                dispatch(showLoader({ open: false, message: "" }));
                alert("Error deleting user data");
            });
        }
    };

    const logOut = async () => {
        setProfileAnchorEl(null);
        dispatch(showLoader({ open: true, message: "Logging out, please wait..." }));
        dispatch(clear());
        await Auth.signOut();
        window.location.replace("/");
    };
    const handleCloseProfile = () => {
        setProfileAnchorEl(null);
    };

    const drawerContent = (
        <>
            <Toolbar
                sx={{
                    width: drawerWidth,
                    height: topNavHeight,
                }}
            >
                <Typography>
                    Hostly.AI
                </Typography>
            </Toolbar>
            <StyledList>
                <ListItemButton
                    LinkComponent={NavLink}
                    to="/dashboard"
                    className="main-btn"
                >
                    <ListItemIcon className="main-icon">
                        <Dashboard />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItemButton>
                <ListItem
                    sx={{
                        padding: 0,
                    }}
                >
                    <ListItemButton
                        LinkComponent={NavLink}
                        to="/property"
                        className="main-btn"
                    >
                        <ListItemIcon className="main-icon">
                            <MapsHomeWork />
                        </ListItemIcon>
                        <ListItemText primary="Properties" />
                    </ListItemButton>
                    <ListItemSecondaryAction>
                        <IconButton
                            onClick={() => setShowProperties(!showProperties)}
                        >
                            {showProperties ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                <Collapse
                    in={showProperties}
                    timeout="auto"
                    unmountOnExit
                >
                    <List
                        sx={{
                            maxHeight: 300,
                            overflow: "auto",
                        }}
                    >
                        {isGettingProperties && (
                            <ListItemButton>
                                Updating...
                            </ListItemButton>
                        )}
                        {dataGetAllProperties !== undefined && dataGetAllProperties.map((property) => (
                            <ListItemButton
                                key={property.id}
                                LinkComponent={NavLink}
                                to={`/property/${property.id}`}
                                className="sub-btn"
                            >
                                {property.propertyName}
                            </ListItemButton>
                        ))}
                    </List>
                </Collapse>
                <ListItemButton
                    LinkComponent={NavLink}
                    to="/servicerequest"
                    className="main-btn"
                >
                    <ListItemIcon className="main-icon">
                        <HomeRepairServiceIcon />
                    </ListItemIcon>
                    <ListItemText primary="Service Requests" />
                </ListItemButton>
                <ListItemButton
                    LinkComponent={NavLink}
                    to="/iotsimulator"
                    className="main-btn"
                >
                    <ListItemIcon className="main-icon">
                        <SmartToy />
                    </ListItemIcon>
                    <ListItemText primary="IoT Simulator" />
                </ListItemButton>
            </StyledList>
        </>
    );

    return (
        <>
            <Box sx={{ flexgrow: 1 }}>
                <AppBar position="sticky" sx={{ backgroundColor: "white" }} elevation={0}>
                    <Toolbar sx={{ color: "primary.dark", height: topNavHeight }}>
                        <Box sx={{
                            flexGrow: 1,
                        }}
                        >
                            {signUpProcessFlow.isSignUpStatusComplete ? (
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                >
                                    <IconButton
                                        color="primary"
                                        onClick={() => setToggleSideNav(true)}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Typography
                                        ml={isMobile ? 0 : theme.spacing(33)}
                                        width="100%"
                                        textAlign={isMobile ? "center" : "left"}
                                        fontWeight="bold"
                                    >
                                        {pageTitle}
                                    </Typography>
                                </Stack>
                            ) : (<>&nbsp;</>)}

                        </Box>
                        <Typography>{currentDateTime}</Typography>
                        {!isAuthenticated ? (
                            <>
                                <Button
                                    id="basic-button1"
                                    component={RouterLink}
                                    color="inherit"
                                    to="/signup"
                                >
                                    Sign Up
                                </Button>
                                <Button
                                    id="basic-button"
                                    component={RouterLink}
                                    color="inherit"
                                    to="/login"
                                >
                                    Log In
                                </Button>
                            </>
                        ) : (
                            <>
                                <NotificationsMenuIconButton />
                                <IconButton
                                    color="inherit"
                                    onClick={(e) => {
                                        setProfileAnchorEl(e.currentTarget);
                                    }}
                                >
                                    <AccountCircle />
                                </IconButton>
                                <Menu
                                    open={openProfile}
                                    anchorEl={profileAnchorEl}
                                    onClose={handleCloseProfile}
                                >
                                    {signUpProcessFlow.isSignUpStatusComplete && (
                                        <MenuItem
                                            component={RouterLink}
                                            color="inherit"
                                            variant="text"
                                            to="/settings"
                                            onClick={handleCloseProfile}
                                        >
                                            <ListItemIcon>
                                                <AccountCircle />
                                            </ListItemIcon>
                                            {userInfo.firstName}
                                        </MenuItem>
                                    )}
                                    {/* {signUpProcessFlow.isSignUpStatusComplete && (
                                        <MenuItem
                                            component={RouterLink}
                                            color="inherit"
                                            variant="text"
                                            to="/changepassword"
                                            onClick={handleCloseProfile}
                                        >
                                            <ListItemIcon>
                                                <Security />
                                            </ListItemIcon>
                                            Security
                                        </MenuItem>
                                    )} */}
                                    <MenuItem onClick={deleteAllUserData}>
                                        <ListItemIcon>
                                            <DeleteForeverSharp />
                                        </ListItemIcon>
                                        Account Wipe
                                    </MenuItem>
                                    <MenuItem onClick={logOut}>
                                        <ListItemIcon>
                                            <Logout />
                                        </ListItemIcon>
                                        Log Out
                                    </MenuItem>
                                </Menu>
                            </>
                        )}
                    </Toolbar>
                </AppBar>
            </Box>
            {signUpProcessFlow.isSignUpStatusComplete && (
                <>
                    <Drawer
                        variant="permanent"
                        anchor="left"
                        sx={{
                            display: {
                                xs: "none",
                                sm: "none",
                                md: "block",
                            },
                        }}
                    >
                        {drawerContent}
                    </Drawer>
                    <Drawer
                        variant="temporary"
                        anchor="left"
                        sx={{
                            display: {
                                xs: "block",
                                sm: "block",
                                md: "none",
                            },
                        }}
                        open={toggleSideNav}
                        onClose={() => setToggleSideNav(false)}
                        ModalProps={{
                            keepMounted: true, // better open performance for mobile
                        }}
                    >
                        {drawerContent}
                    </Drawer>
                </>
            )}

        </>
    );
}
