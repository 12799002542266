import { Badge, Button, Dialog, DialogActions, DialogTitle, Fab, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { ChatBubble, Person } from "@mui/icons-material";
import { useSelector } from "react-redux";
import ChatForm from "./ChatForm";

export function ChatNotif() {
    const theme = useTheme();
    const [showNotifList, setShowNotifList] = useState(false);
    const [showChatForm, setShowChatForm] = useState(false);
    const [notifList, setNotifList] = useState([]);
    const [selectedNotif, setSelectedNotif] = useState(null);
    const chats = useSelector((state) => state.ws.chat);

    useEffect(() => {
        if (chats && chats.length > 0) {
            const result = [];

            chats.forEach((item) => {
                if (item.messageType !== "sender") {
                    let dataIndex = -1;
                    if (result.length > 0) dataIndex = result.findIndex((e) => e.spId === item.spId);

                    if (dataIndex === -1) {
                        const notifObj = {
                            spId: item.spId,
                            spName: item.spName,
                            count: 1,
                        };

                        result.push(notifObj);
                    } else {
                        result[dataIndex].count += 1;
                    }
                }
            });
            setNotifList(result);
        }
    }, [chats]);

    return (
        <>
            {showChatForm && (
                <ChatForm
                    spId={selectedNotif.spId}
                    spName={selectedNotif.spName}
                    show={showChatForm}
                    callbackCloseForm={(e) => setShowChatForm(e)}
                />
            )}

            {notifList.length > 0 && (
                <Dialog
                    open={showNotifList}
                >
                    <DialogTitle>
                        New Message
                    </DialogTitle>
                    {/* <DialogContent> */}
                    <List dense>
                        {notifList.map((item, idx) => {
                            const indx = idx;
                            return (
                                <ListItem key={indx}>
                                    <ListItemButton
                                        onClick={() => {
                                            setSelectedNotif(item);
                                            // remove sp info from notif list
                                            const indexToRemove = notifList.findIndex((e) => e.spId === item.spId);
                                            notifList.splice(indexToRemove, 1);
                                            setShowNotifList(false);
                                            setShowChatForm(true);
                                        }}
                                    >
                                        <ListItemIcon>
                                            <Person />
                                        </ListItemIcon>
                                        <ListItemText primary={item.spName} />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                    {/* </DialogContent> */}
                    <DialogActions>
                        <Button
                            size="small"
                            onClick={() => setShowNotifList(false)}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            <Fab
                size="small"
                sx={{
                    position: "fixed",
                    bottom: theme.spacing(3),
                    right: theme.spacing(3),
                    display: notifList.length > 0 ? "flex" : "none",
                }}
                onClick={() => setShowNotifList(true)}
            >
                <Badge
                    badgeContent="new"
                    anchorOrigin={{
                        horizontal: "left",
                        vertical: "top",
                    }}
                    color="error"
                >
                    <ChatBubble
                        color="primary"
                    />
                </Badge>

            </Fab>
        </>
    );
}
