import { API, Auth } from "aws-amplify";
// eslint-disable-next-line import/no-extraneous-dependencies
import ReconnectingWebSocket from "reconnecting-websocket";
import { hostAPI } from "./hostAPI";
import { setChat, setNotification, setSocketState } from "./websocketSlice";

const urlProvider = async () => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    return `${process.env.REACT_APP_WEBSOCKET}?userType=host&token=${token}`;
};

// let socket;

// function getWebSocket() {
//     return new ReconnectingWebSocket(urlProvider, null, {
//         debug: true,
//     });
// }

// https://www.npmjs.com/package/reconnecting-websocket
export const socket = new ReconnectingWebSocket(urlProvider, null, {
    debug: false,
});

const wsAPI = hostAPI.injectEndpoints({
    endpoints: (builder) => ({
        getChatMessage: builder.query({
            queryFn: () => ({ data: [] }),
            async onCacheEntryAdded(arg, {
                cacheDataLoaded,
                cacheEntryRemoved,
                updateCachedData,
                dispatch,
                getState,
            }) {
                try {
                    await cacheDataLoaded;

                    // socket = getWebSocket();
                    socket.onmessage = (event) => {
                        console.log("onmessage", new Date());
                        const data = JSON.parse(event.data);

                        if (data.MessageType === "sender" || data.MessageType === "recepient") {
                            const msgObj = {
                                messageType: data.MessageType,
                                message: data.Message,
                                dateSent: data.DateSent,
                                id: data.Id,
                                hostId: data.HostId,
                                spId: data.SpId,
                                spName: data.SpName,
                            };
                            const { selectedSpId } = getState().ws;

                            if (selectedSpId === 0 || selectedSpId !== msgObj.spId) dispatch(setChat(msgObj));
                            updateCachedData((draft) => {
                                draft.push(msgObj);
                            });
                        } else if (data.MessageType === "notif") {
                            const msg = JSON.parse(data.Message);
                            const notifData = {
                                notifications: msg.Notifications,
                                unreadCount: msg.UnreadCount,
                            };
                            dispatch(setNotification(notifData));
                        }
                    };

                    socket.onopen = (event) => {
                        console.log("socket.onopen", new Date());
                        dispatch(setSocketState(socket.readyState));
                    };
                    socket.onerror = (event) => {
                        console.log("socket.onopen", new Date());
                        dispatch(setSocketState(socket.readyState));
                    };
                    socket.onclose = (event) => {
                        console.log("socket.onopen", new Date());
                        dispatch(setSocketState(socket.readyState));
                    };
                    await cacheEntryRemoved;

                    socket.removeEventListener("open");
                    socket.removeEventListener("close");
                    socket.removeEventListener("message");
                    socket.removeEventListener("error");
                    socket.close();
                } catch (error) {
                    console.log("onCacheEntryAdded", error);
                }
            },
            providesTags: [{
                type: "getChatMessage",
                id: "list",
            }],
        }),
        sendChatMessage: builder.mutation({
            queryFn: async ({
                msg,
            }) => {
                try {
                    const jsonMsg = JSON.stringify(msg);
                    return new Promise((resolve) => {
                        socket.send(jsonMsg);
                        console.log("send message", new Date());
                        resolve({ data: jsonMsg });
                    });
                } catch (error) {
                    return { error };
                }
            },
        }),
        getChatHistory: builder.query({
            queryFn: async ({
                recepientId,
                page,
                pageSize,
            }) => {
                try {
                    const response = await API.get("WebSocketAPI", `/chatHistory/${recepientId}/${page}/${pageSize}`);
                    return {
                        data: response,
                    };
                } catch (error) {
                    return { error };
                }
            },
        }),
    }),
});

export const {
    useGetChatMessageQuery,
    useLazyGetChatHistoryQuery,
    useSendChatMessageMutation,
    useGetChatHistoryQuery,
} = wsAPI;
