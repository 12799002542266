import { ChatBubble } from "@mui/icons-material";
import { IconButton, useTheme } from "@mui/material";
import { useState } from "react";
import ChatForm from "./ChatForm";

export default function ChatButton({ spInfo }) {
    const theme = useTheme();
    const [showChatForm, setShowChatForm] = useState(false);

    const onClickShowChatForm = () => {
        setShowChatForm(true);
    };

    return (
        <>
            {showChatForm && (
                <ChatForm
                    spId={spInfo.sP_Id}
                    spName={spInfo.name}
                    show={showChatForm}
                    callbackCloseForm={(e) => setShowChatForm(e)}
                />
            )}
            <IconButton
                onClick={onClickShowChatForm}
            >
                <ChatBubble
                    sx={{
                        color: `${theme.palette.primary.main}!important`,
                    }}
                />
            </IconButton>
        </>
    );
}
