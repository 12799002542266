import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { setAuthenticationStatus, setUserInfo, updateSignUpProcessFlow } from "../features/auth/authSlice";
import NavMenu from "./NavMenu";
import LoaderComponent from "./helper/LoaderComponent";
import { ChatNotif } from "./Chat/ChatNotif";

export default function Layout() {
    const theme = useTheme();
    const mobileBreakpoint = useSelector((state) => state.auth.mobileBreakpoint);
    const signUpProcessFlow = useSelector((state) => state.auth.signUpProcessFlow);
    const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));
    const routeLoaderData = useLoaderData();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (routeLoaderData) {
            try {
                if (routeLoaderData.user) {
                    const { auth, user, checkSignUpStatus } = routeLoaderData;
                    const isFirstNameExist = auth.attributes.given_name !== auth && auth.attributes.given_name !== "";
                    const isLastnameExist = auth.attributes.family_name !== auth && auth.attributes.family_name !== "";

                    dispatch(setUserInfo({
                        id: user.id,
                        firstName: isFirstNameExist ? auth.attributes.given_name : "",
                        lastName: isLastnameExist ? auth.attributes.family_name : "",
                        standardCheckInTime: user.standardCheckInTime,
                        standardCheckOutTime: user.standardCheckOutTime,
                        isStandardTimeUpdated: user.isStandardTimeUpdated,
                        initialPropertyId: user.initialPropertyId,
                        initialPropertyTimezone: user.initialPropertyTimeZone,

                        // Tomorrow Report
                        isTomorrowReportActive: user.isTomorrowReportActive,
                        localTimeZoneDatabaseName: user.localTimeZoneDatabaseName,
                        tomorrowReportTimeSpan: user.tomorrowReportTimeSpan,
                    }));
                    dispatch(setAuthenticationStatus(true));

                    // redirect to complete registration if sign up process is not complete
                    dispatch(updateSignUpProcessFlow({ statusCode: checkSignUpStatus.statusCode }));
                    if (checkSignUpStatus.navigate !== "" && location.pathname !== "/complete-registration") { navigate(checkSignUpStatus.navigate); }
                }
            } catch (error) {
                console.log(">>", error);
            }
        }
    }, [routeLoaderData]);

    const drawerWidth = signUpProcessFlow.isSignUpStatusComplete ? (isMobile ? 0 : 300) : 0;

    if (routeLoaderData?.user) {
        return (
            <div>
                <NavMenu
                    drawerWidth={drawerWidth}
                />
                <ChatNotif />
                <LoaderComponent />
                <Box
                    sx={{
                        marginTop: theme.spacing(2),
                        marginLeft: "auto",
                        width: `calc(100% - ${drawerWidth}px)`,
                    }}
                >
                    <Container
                        maxWidth="lg"
                    >
                        <Outlet />
                    </Container>
                </Box>
            </div>
        );
    }
    return (
        <div>
            <LoaderComponent />
            <Container sx={{ paddingTop: 0, minHeight: "100vh" }} maxWidth="lg">
                <Outlet />
            </Container>
        </div>
    );
}
