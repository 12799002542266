import { API } from "aws-amplify";
import { hostAPI } from "./hostAPI";

const devicesAPI = hostAPI.injectEndpoints({
    endpoints: (builder) => ({
        getDevicesAndStatesByPropertyId: builder.query({
            queryFn: async ({ propertyId }) => {
                try {
                    const response = await API.get(
                        "IoTAPI",
                        `/devices/properties/${propertyId}/states`,
                    );

                    const newResponse = [];
                    response.data.forEach((element) => {
                        const newData = {
                            device: {},
                            states: [],
                        };
                        newData.device = element.device;

                        const newStateArray = [];

                        element.states.forEach((state) => {
                            const newState = {};
                            newState.deviceTypeId = state.deviceTypeId;
                            newState.deviceStateId = state.deviceStateId;
                            newState.name = state.name;

                            if (state.name === "unreachable_by_partner") newState.displayName = "No response";
                            else {
                                newState.displayName = state.name.charAt(0).toUpperCase() + state.name.slice(1);
                            }
                            newStateArray.push(newState);
                        });

                        newData.states = newStateArray;
                        newResponse.push(newData);
                    });

                    return { data: newResponse };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            providesTags: (result, error, id) => [
                {
                    type: "deviceStates",
                    id: id.propertyId,
                },
            ],
        }),
        getDevicesByPropertyId: builder.query({
            queryFn: async ({ propertyId }) => {
                try {
                    const response = await API.get(
                        "IoTAPI",
                        `/devices/properties/${propertyId}`,
                    );
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            providesTags: (result, error, id) => [
                {
                    type: "devices",
                    id: id.propertyId,
                },
            ],
        }),
        getPropertyDeviceStatesByProperty: builder.query({
            queryFn: async ({ propertyId }) => {
                try {
                    const response = await API.get(
                        "IoTAPI",
                        `/devices/propertyDeviceState/${propertyId}`,
                    );
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            providesTags: (result, error, id) => [
                {
                    type: "propertydevicestate",
                    id: id.propertyId,
                },
            ],
        }),
        deletePropertyDeviceState: builder.mutation({
            queryFn: async ({ propertyId, displayName }) => {
                try {
                    let params = "/";
                    if (displayName !== undefined) {
                        params += displayName;
                    }
                    const response = await API.del(
                        "IoTAPI",
                        `/devices/propertyDeviceState/${propertyId}${params}`,
                    );
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            invalidatesTags: (result, error, arg) => [
                {
                    type: "propertydevicestate",
                    id: arg.propertyId,
                },
                {
                    type: "devices",
                    id: arg.propertyId,
                },
            ],
        }),
        addDevices: builder.mutation({
            queryFn: async ({ devicesArray }) => {
                try {
                    const response = await API.post(
                        "IoTAPI",
                        "/devices",
                        devicesArray,
                    );
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data.data,
                            message: error?.response?.data?.data.displayMessages,
                        },
                    };
                }
            },
        }),
        addUpdatePropertyDeviceState: builder.mutation({
            queryFn: async ({ devicesArray }) => {
                try {
                    const myInit = {
                        body: {
                            devices: devicesArray,
                        },
                    };
                    const response = await API.post(
                        "IoTAPI",
                        "/devices/propertyDeviceState/pending",
                        myInit,
                    );
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.response?.data?.message,
                        },
                    };
                }
            },
            invalidatesTags: (result, error, arg) => [
                {
                    type: "propertydevicestate",
                    id: arg.devicesArray[0].propertyId,
                },
                {
                    type: "devices",
                    id: arg.devicesArray[0].propertyId,
                },
                {
                    type: "partners",
                    id: "list",
                },
            ],
        }),
        updateDevice: builder.mutation({
            queryFn: async ({ device }) => {
                try {
                    const myInit = {
                        body: {
                            ...device,
                        },
                    };
                    const response = await API.put("IoTAPI", "/devices", myInit);
                    return { data: response };
                } catch (error) {
                    return {
                        error: {
                            data: device,
                            message:
                                error?.response.data.message
                                ?? `Failed to update ${error?.response.data.data.displayName} due to an unexpected error. Please try again later.`,
                        },
                    };
                }
            },
            invalidatesTags: (result, error, arg) => [
                {
                    type: "propertydevicestate",
                    id: arg.device.propertyId,
                },
                {
                    type: "devices",
                    id: arg.device.propertyId,
                },
                {
                    type: "deviceStates",
                    id: arg.device.propertyId,
                },
                {
                    type: "ruleGroups",
                    id: arg.device.propertyId,
                },
            ],
        }),
        disableDevice: builder.mutation({
            queryFn: async ({ id }) => {
                try {
                    const response = await API.put(
                        "IoTAPI",
                        `/devices/disable/${id}`,
                    );
                    return { data: response };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: `Failed to delete ${error?.response.data.data.displayName} due to an unexpected error. Please try again later.`,
                        },
                    };
                }
            },
            invalidatesTags: (result, error, arg) => [
                {
                    type: "propertydevicestate",
                    id: result.data.device.propertyId,
                },
                {
                    type: "devices",
                    id: result.data.device.propertyId,
                },
                {
                    type: "ruleGroups",
                    id: result.data.device.propertyId,
                },
                {
                    type: "deviceStates",
                    id: result.data.device.propertyId,
                },
            ],
        }),
        createHouseTimer: builder.mutation({
            queryFn: async () => {
                try {
                    const response = await API.post(
                        "IoTAPI",
                        "/devices/houseTimer",
                    );
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message:
                          "Failed to create the house timer due to an unexpected error. Please try again later.",
                        },
                    };
                }
            },
        }),
        updateAllDeviceStates: builder.mutation({
            queryFn: async ({ propertyId }) => {
                try {
                    const response = await API.post(
                        "IoTAPI",
                        `/devices/refresh/${propertyId}`,
                    );
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message:
                                "Failed to update all device states in this property due to an unexpected error. Please try again later.",
                        },
                    };
                }
            },
            invalidatesTags: (result, error, arg) => [
                {
                    type: "devices",
                    id: arg.propertyId,
                },
            ],
        }),
        getDeviceTypes: builder.query({
            queryFn: async () => {
                try {
                    const response = await API.get("IoTAPI", "/devicetypes/*");

                    const newResponse = [];
                    response.data.forEach((element) => {
                        const newData = structuredClone(element);
                        switch (element.name) {
                            case "doorSensor":
                                newData.displayName = "Door Sensor";
                                break;
                            case "leakSensor":
                                newData.displayName = "Leak Sensor";
                                break;
                            case "motionSensor":
                                newData.displayName = "Motion Sensor";
                                break;
                            case "THSensor":
                                newData.displayName = "Thermal Sensor";
                                break;
                            case "hub":
                                newData.displayName = "Hub";
                                break;
                            case "SmartRemoter":
                                newData.displayName = "Smart Remoter";
                                break;
                            case "Thermostat":
                                newData.displayName = "Thermostat";
                                break;
                            case "alertDevice":
                                newData.displayName = "Alert Device";
                                break;

                            default:
                                newData.displayName = element.name;
                                break;
                        }
                        newResponse.push(newData);
                    });

                    return {
                        data: newResponse,
                    };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
        }),
        getDevicePartners: builder.query({
            queryFn: async ({
                apiBody,
            }, {
                dispatch,
                getState,
                endpoint,
            }) => {
                try {
                    const response = await API.post("IoTAPI", "/devices/partner", apiBody);

                    const parsedJson = JSON.parse(response.data);

                    const promise = dispatch(hostAPI.endpoints.getAllPartners.initiate());
                    const { refetch } = promise;
                    refetch();

                    return {
                        data: parsedJson,
                    };
                } catch (error) {
                    // console.log("getDevicePartners.error", JSON.stringify(error));
                    // return {
                    //     error: {
                    //         data: error?.response?.data,
                    //         message: error?.message,
                    //     },
                    // };
                    return { error: { ...error?.response } };
                }
            },
            providesTags: (result, error, id) => [
                {
                    type: "devicePartners",
                    id: "list",
                },
            ],
        }),
    }),
});

export const {
    useGetDevicesAndStatesByPropertyIdQuery,
    useGetDevicesByPropertyIdQuery,
    useLazyGetDevicesByPropertyIdQuery,
    useLazyGetPropertyDeviceStatesByPropertyQuery,
    useGetPropertyDeviceStatesByPropertyQuery,
    useAddDevicesMutation,
    useUpdateDeviceMutation,
    useDisableDeviceMutation,
    useDeletePropertyDeviceStateMutation,
    useAddUpdatePropertyDeviceStateMutation,
    useUpdateAllDeviceStatesMutation,
    useCreateHouseTimerMutation,
    useGetDeviceTypesQuery,
    useGetDevicePartnersQuery,
} = devicesAPI;
