import { useAuthenticator } from "@aws-amplify/ui-react";
import { Alert, Box, Button, Divider, Grid, Typography, useTheme } from "@mui/material";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setSignUpProcess, showLoader } from "../features/auth/authSlice";
import VerifyOtp from "./VerifyOtp";
import HouseCard from "./helper/HouseCard";
import CustomPasswordField from "./shared/custom_password";
import CustomPhoneInput from "./shared/custom_phoneinput";
import { isPasswordValid, CustomPasswordRequirements, CustomPasswordConfirmationRequirements } from "./shared/custom_password_validator";

export const SIGNUP_STEP = "Sign Up";
export const SIGNUP_VERIFYOTP_STEP = "Verify OTP";
export const SIGNUP_UPDATEPROFILE_STEP = "Update Profile";

export default function SignUp() {
    const theme = useTheme();
    const [phone, setPhone] = useState("");
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const currentSignUpProcess = useSelector((state) => state.auth.signUpProcess);
    const dispatch = useDispatch();
    const [errorPhoneField, setErrorPhoneField] = useState("");
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const [formIsComplete, setFormIsComplete] = useState(false);
    const { route } = useAuthenticator((context) => [context.route]);
    const location = useLocation();
    const navigate = useNavigate();
    const from = location.state?.from?.pathname || "/";

    useEffect(() => {
        console.log("route", route);
        if (route === "authenticated") {
            navigate(from, { replace: true });
            dispatch(setSignUpProcess(""));
        } else {
            dispatch(setSignUpProcess(SIGNUP_STEP));
        }
    }, [route]);

    useEffect(() => {
        if ((phone !== null && phone.length !== 0)
            && isPasswordValid(password)
            && (password === confirmPassword)) setFormIsComplete(true);
        else setFormIsComplete(false);
    }, [phone, password, confirmPassword]);

    const signUp = async () => {
        setHasSubmitted(true);
        dispatch(showLoader({ open: false, message: "" }));

        const phoneWithPlus = phone[0] === "+" ? phone : `+${phone}`;
        dispatch(showLoader({ open: true, message: "Signing Up. Please wait..." }));
        setErrorPhoneField("");

        try {
            const _user = phone.replace("+", "").replace(/\s+/g, "").trim();
            await Auth.signUp({
                username: _user,
                password,
                autoSignIn: {
                    enabled: true,
                },
                attributes: {
                    phone_number: phoneWithPlus,
                },
            });

            setUser(_user);

            dispatch(setSignUpProcess(SIGNUP_VERIFYOTP_STEP));
        } catch (err) {
            setHasSubmitted(false);
            dispatch(showLoader({ open: false, message: "" }));
            if (err.code === "UsernameExistsException") {
                setErrorPhoneField("User already exists.");
            } else if (err.message === "Username cannot be empty") {
                setErrorPhoneField(err.message);
            } else if (err.message.includes("Encountered an error while signing up")) {
                setErrorPhoneField("Something went wrong. Please try again later.");
            } else {
                setErrorPhoneField(err.message);
            }
        } finally {
            dispatch(showLoader({ open: false, message: "" }));
        }
    };

    const handleEnterKeyPress = (e) => {
        if (e.key !== "Enter") return;
        if (hasSubmitted) return;
        if (!formIsComplete) return;
        signUp();
    };

    const handlePhoneOnChange = (phoneNumber) => {
        setPhone(phoneNumber);
        setErrorPhoneField("");
    };

    return (
        <HouseCard px={8} py={4}>
            {currentSignUpProcess === SIGNUP_STEP && (
                <Box height="100%" display="flex" flexDirection="column">
                    <Box flex={1} display="flex" flexDirection="column" justifyContent="center">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    Sign Up
                                </Typography>
                            </Grid>
                            {(errorPhoneField !== "") && (
                                <Grid item xs={12}>
                                    <Alert severity="error" onClose={() => setErrorPhoneField("")}>{errorPhoneField}</Alert>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <CustomPhoneInput
                                    autoFocus
                                    label="Phone"
                                    fullWidth
                                    onChange={(e) => {
                                        setPhone(e);
                                        setErrorPhoneField("");
                                    }}
                                    onKeyPress={handleEnterKeyPress}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomPasswordField
                                    label="Password"
                                    onKeyDown={handleEnterKeyPress}
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                />
                                <CustomPasswordRequirements
                                    password={password}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomPasswordField
                                    label="Re-enter Password"
                                    onKeyDown={handleEnterKeyPress}
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value);
                                    }}
                                />
                                <CustomPasswordConfirmationRequirements
                                    password={password}
                                    confirmPassword={confirmPassword}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    onClick={signUp}
                                    fullWidth
                                    disabled={!formIsComplete}
                                >
                                    Sign Up
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ marginTop: theme.spacing(1), textAlign: "center" }}>
                                    <Typography variant="subtitle2">
                                        Already have an account?&nbsp;
                                        <Link to="/login">Log In</Link>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Divider />
                    </Box>
                    <Box textAlign="center" mt={3}>
                        <Typography variant="subtitle2">
                            Sign Up as a&nbsp;
                            <Link to={`${process.env.REACT_APP_SERVICE_PROVIDER_URL}/signup`}>Service Provider</Link>
                        </Typography>
                    </Box>
                </Box>
            )}
            {currentSignUpProcess === SIGNUP_VERIFYOTP_STEP && <VerifyOtp phoneNumber={user} />}
        </HouseCard>
    );
}
