import { Typography } from "@mui/material";

const validations = [
    {
        description: "Must have both upper and lowercase letters.",
        // returns true if password has at least one uppercase char & at least one lowercase char
        validator: (password) => password.match(/[A-Z]/) && password.match(/[a-z]/),
    },
    {
        description: "Must contain at least one number.",
        validator: (password) => password.match(/[0-9]/),
    },
    {
        description: "Must have a minimum of eight characters.",
        validator: (password) => password.length >= 8,
    },
];

export function isPasswordValid(password) {
    return validations.every((validation) => validation.validator(password));
}

export function CustomPasswordRequirements({ password }) {
    return validations.map((validation) => (
        <Typography
            component="div"
            variant="caption"
            color={validation.validator(password) ? "success.main" : "error.main"}
            key={validation.description}
        >
            {`${validation.validator(password) ? "✓" : "✗"} ${validation.description}`}
        </Typography>
    ));
}

export function CustomPasswordConfirmationRequirements({ password, confirmPassword }) {
    return confirmPassword && (
        <Typography
            component="div"
            variant="caption"
            color={password === confirmPassword ? "success.main" : "error.main"}
        >
            {password === confirmPassword ? "✓ Passwords match." : "✗ Passwords must match."}
        </Typography>
    );
}
